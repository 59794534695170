export const getPageFromQueryObject = (query: any, key: string, defaultValue: number): number => {
  if (typeof query === 'object') {
    if (query[key]) {
      try {
        return parseInt(query[key], 10);
      } catch (error) {
        return defaultValue;
      }
    }
  }

  return defaultValue;
};
